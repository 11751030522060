import { MaskedPatternOptions } from 'imask/esm/index';

/** Allows to type numbers only. */
export const numberMask = { mask: /^\d+$/ } as const;

/** Allow to type currency. */
export const currencyMask = {
	mask: '$num',
	blocks: {
		num: {
			mask: Number,
			scale: 2,
			thousandsSeparator: ',',
			padFractionalZeros: false,
			radix: '.',
			normalizeZeros: true,
			max: 99_999_999.99,
			min: 0,
		},
	},
};

/** Phone number pattern mask. */
export const phoneNumberMask: MaskedPatternOptions = {
	mask: '000-000-0000',
} as const;

/** NPI number pattern mask. */
export const npiNumberMask = {
	mask: '0000000000',
} as const;

/** OTP confirmation code mask. */
export const otpCodeMask = {
	mask: '000000',
};

/** ZIP code mask. */
export const zipCodeMask = {
	mask: [
		{
			mask: '00000',
		},
		{
			mask: '00000-0000',
		},
	],
};
