/** Validation error code. */
export enum ValidationErrorCode {

	/** Wrong email. */
	Email = 'email',

	/** Required field. */
	Required = 'required',

	/** Match of values error. When value of one control does not match to another. */
	Match = 'match',

	/** Match of values error. When value of one control matches to another. */
	NotMatch = 'notMatch',

	/** Minimal length restriction. */
	MinLength = 'minlength',

	/** Maximal length restriction. */
	MaxLength = 'maxlength',

	/** Maximum value restriction. */
	Min = 'min',

	/** Minimum value restriction. */
	Max = 'max',

	/** Pattern restriction. */
	Pattern = 'pattern',

	/** Custom error. */
	AppError = 'appError',

	/** Value is greater than the compared one. */
	Greater = 'greater',

	/** Max size of uploaded files. */
	MaxFileSize = 'maxFileSize',

	/** Phone number. */
	PhoneNumber = 'phoneNumber',

	/** Fax number. */
	FaxNumber = 'faxNumber',

	/** ZIP code. */
	ZipCode = 'zipCode',

	/** NPI number. */
	NPI = 'npi',

	/** Max count of uploaded files. */
	MaxFileCount = 'maxCountFiles',

	/** Empty file' content type. */
	EmptyContentType = 'emptyContentType',

	/** Min size of a file. */
	MinFileSize = 'minFileSize',
}

/** Match validation error data. */
export type MatchErrorData = {

	/** Control name. */
	controlName: string;

	/** Control title. */
	controlTitle: string;
};

/** Length validation error data. */
export type LengthErrorData = {

	/** Actual length. */
	actualLength: number;

	/** Required length. */
	requiredLength: number;
};

/** Pattern validation error data. */
export type PatternErrorData = {

	/** Actual length. */
	actualValue: string;

	/** Required length. */
	requiredPattern: string;
};

/** App validation error data. */
export type AppErrorData = {

	/** Message. */
	message: string;
};

/** Min value validation error data. */
export type MinValueErrorData = {

	/** Actual value. */
	actual: number;

	/** Min value. */
	min: number;
};

/** Max value validation error data. */
export type MaxValueErrorData = {

	/** Actual value. */
	actual: number;

	/** Max value. */
	max: number;
};

/** Max file size validation error data. */
export type MaxFileSizeErrorData = {

	/** Actual value in bytes. */
	actualInBytes: number;

	/** Max value in bytes. */
	maxInBytes: number;

	/** Filename. */
	filename?: string;
};

/** Min file size validation error data. */
export type MinFileSizeErrorData = {

	/** Actual value in bytes. */
	actualInBytes: number;

	/** Min value in bytes. */
	minInBytes: number;

	/** Filename. */
	filename?: string;
};

/** Max files count error data. */
export type MaxFilesCountErrorData = {

	/** Actual count. */
	actualCount: number;

	/** Required count. */
	requiredCount: number;
};

/** Empty content type error data. */
export type EmptyContentTypeErrorData = {

	/** Filename. */
	filename: string;
};
