import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MaskedPattern } from 'imask/esm/index';

import { ValidationErrorCode } from '../models/validation-error-code';
import { AppError } from '../models/app-error';

import { npiNumberMask, phoneNumberMask, zipCodeMask } from './masks';

/** Extract values from form control. */
export type FormControlValueExtractor<T> = (control: AbstractControl<T>) => unknown;

/** Unique value validator options. */
export type UniqueValueOpts<T> = {

	/** Form control value extractor. */
	readonly extractorFn?: FormControlValueExtractor<T>;

	/** Error message. */
	readonly message: string;
};

export namespace AppValidators {

	/**
	 * Checks whether the current control matches another.
	 * @param controlName Control name to check matching with.
	 * @param controlTitle Control title to display for a user.
	 */
	export function matchControl(controlName: string, controlTitle = controlName): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.parent && control.parent.get(controlName)?.value !== control.value) {
				return {
					[ValidationErrorCode.Match]: {
						controlName,
						controlTitle,
					},
				};
			}
			return null;
		};
	}

	/**
	 * Validate control with list of files so each file should not exceed the maximum size.
	 * @param maxInBytes Maximum file size in bytes.
	 */
	export function eachFileMaxSize(maxInBytes: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const controlValue = control.value;
			if (controlValue instanceof Array) {
				for (const value of controlValue) {
					if (value instanceof File && value.size > maxInBytes) {
						return {
							[ValidationErrorCode.MaxFileSize]: {
								maxInBytes,
								actualInBytes: value.size,
								filename: value.name,
							},
						};
					}
				}
			}
			return null;
		};
	}

	/**
	 * Validate control with list of files so each file should not be less than the minimum size.
	 * @param minInBytes Minimum file size in bytes.
	 */
	export function eachFileMinSize(minInBytes: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const controlValue = control.value;
			if (controlValue instanceof Array) {
				for (const value of controlValue) {
					if (value instanceof File && value.size < minInBytes) {
						return {
							[ValidationErrorCode.MinFileSize]: {
								minInBytes,
								actualInBytes: value.size,
								filename: value.name,
							},
						};
					}
				}
			}
			return null;
		};
	}

	/**
	 * Validate control with a file or list of files so each file has non-empty content type value.
	 * @param control Abstract control to validate.
	 */
	export function isValidContentType(control: AbstractControl): ValidationErrors | null {
		const controlValue = Array.isArray(control.value) ? control.value : [control.value];
		for (const value of controlValue) {
			if (value instanceof File && value.type === '') {
				return {
					[ValidationErrorCode.EmptyContentType]: {
						filename: value.name,
					},
				};
			}
		}
		return null;
	}

	/**
	 * Validate max length with list of files.
	 * @param maxCount Maximum count of files.
	 */
	export function maxFilesCount(maxCount: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const controlValue = control.value;
			if (controlValue instanceof Array) {
				if (controlValue.length > maxCount) {
					return {
						[ValidationErrorCode.MaxFileCount]: {
							actualCount: controlValue.length,
							requiredCount: maxCount,
						},
					};
				}
			}
			return null;
		};
	}

	/**
	 * Checks whether the current control doesn't match another.
	 * @param controlName Control name to check matching with.
	 * @param controlTitle Control title to display for a user.
	 */
	export function notMatchControl(controlName: string, controlTitle = controlName): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.parent && control.parent.get(controlName)?.value === control.value) {
				return {
					[ValidationErrorCode.NotMatch]: {
						controlName,
						controlTitle,
					},
				};
			}
			return null;
		};
	}

	/**
	 * Check whether the main control value matches dependent controls.
	 * @param mainControlName Name of main controls.
	 * @param dependentControlsName List of names of dependent controls.
	 * @param mainControlReadableName Readable name of the main control to apply for the error message.
	 * @description Apply the validator to FormGroup that contain all `mainControlName` and `dependentControlsName`.
	 * The validator always apply the error to the main control as a side-effect and ValidatorFn always return `null`.
	 */
	export function notMatchControls(
		mainControlName: string,
		dependentControlsName: string[],
		mainControlReadableName?: string,
	): ValidatorFn {
		return (formGroup: AbstractControl): ValidationErrors | null => {
			const mainControl = formGroup.get(mainControlName);

			if (!mainControl) {
				throw new AppError(`Main control ${mainControlName} is not found in the form group.`);
			}

			const mainControlValue = mainControl.value;

			dependentControlsName.forEach(dependentControlName => {
				const dependentControl = formGroup.get(dependentControlName);

				if (!dependentControl) {
					throw new AppError(`Dependent control ${dependentControlName} is not found in the form group.`);
				}

				if (mainControlValue === dependentControl.value) {
					mainControl.setErrors({
						[ValidationErrorCode.NotMatch]: {
							controlName: mainControlName,
							controlTitle: mainControlReadableName,
						},
					});
				} else if (mainControl.hasError(ValidationErrorCode.NotMatch)) {
					const errors = { ...mainControl.errors };
					delete errors[ValidationErrorCode.NotMatch];

					mainControl.setErrors(
						Object.keys(errors).length > 0 ? errors : null,
					);
				}
			});

			return null;
		};
	}

	/**
	 * Checks form array for unique values by compare function.
	 * @param options Unique value options.
	 */
	export function uniqueValue<T>(options?: UniqueValueOpts<T>): ValidatorFn {
		const {
			extractorFn = (control: AbstractControl) => control.value,
			message = 'The value must be unique in the list',
		} = options ?? {};

		const composeUniqueValidationErrors = (values: unknown[]): ValidationErrors | null => {
			if (isUniqueValues(values)) {
				return null;
			}

			return {
				[ValidationErrorCode.AppError]: { message },
			};
		};

		return (formControl: AbstractControl): ValidationErrors | null => {
			if (formControl instanceof FormArray) {
				const plainValues = formControl.controls.map(control => extractorFn(control));
				return composeUniqueValidationErrors(plainValues);
			}

			if (formControl instanceof FormGroup) {
				const plainValues = Object.values(formControl.controls).map(control => extractorFn(control));
				return composeUniqueValidationErrors(plainValues);
			}

			throw new AppError(
				'"uniqueValueInArray" validator is supposed to be used with a FormArray or FormGroup instance.',
			);
		};
	}

	/**
	 * Phone number validator.
	 * @param allowEmpty Whether empty string is a valid value.
	 * @param errorCode Error code.
	 */
	function phoneValidator(
		allowEmpty: boolean,
		errorCode: ValidationErrorCode.PhoneNumber | ValidationErrorCode.FaxNumber,
	): ValidatorFn {
		const mask = new MaskedPattern(phoneNumberMask);

		return control => {
			if (allowEmpty && control.value === '') {
				return null;
			}

			mask.value = control.value;
			if (!mask.isComplete || mask.unmaskedValue !== control.value) {
				return {
					[errorCode]: {
						value: control.value,
					},
				};
			}
			return null;
		};
	}

	/**
	 * ZIP code validator.
	 * @param allowEmpty Whether empty string is a valid value.
		*/
	export function zipCode(allowEmpty: boolean): ValidatorFn {
		const mask1 = new MaskedPattern(zipCodeMask.mask[0]);
		const mask2 = new MaskedPattern(zipCodeMask.mask[1]);

		return control => {
			const { value } = control;
			if (typeof value !== 'string') {
				return null;
			}

			if (allowEmpty && value === '') {
				return null;
			}

			mask1.value = value;
			mask2.value = value;

			const isFitFirstMask = mask1.isComplete && value.length === zipCodeMask.mask[0].mask.length;
			const isFitSecondMask = mask2.isComplete && value.length === zipCodeMask.mask[1].mask.length;

			if (!isFitFirstMask && !isFitSecondMask) {
				return {
					[ValidationErrorCode.ZipCode]: {
						value: control.value,
					},
				};
			}

			return null;
		};
	}

	/**
	 * Validates phone number.
	 * @param allowEmpty Whether empty string is a valid value.
	 */
	export function phoneNumber(
		allowEmpty = false,
	): ValidatorFn {
		return phoneValidator(allowEmpty, ValidationErrorCode.PhoneNumber);
	}

	/**
	 * Validates fax number.
	 * @param allowEmpty Whether empty string is a valid value.
	 */
	export function faxNumber(
		allowEmpty = false,
	): ValidatorFn {
		return phoneValidator(allowEmpty, ValidationErrorCode.FaxNumber);
	}

	/** Validates NPI number. */
	export function npiNumber(): ValidatorFn {
		const mask = new MaskedPattern(npiNumberMask);

		return control => {
			mask.value = control.value;
			if (!mask.isComplete || mask.unmaskedValue !== control.value) {
				return {
					[ValidationErrorCode.NPI]: {
						value: control.value,
					},
				};
			}
			return null;
		};
	}

	/**
	 * Create validation error from a message.
	 * @param message Message to create an error from.
	 */
	export function buildAppError(message: string): ValidationErrors {
		return {
			[ValidationErrorCode.AppError]: {
				message,
			},
		};
	}
}

/**
 * Array contains only unique values.
 * @param values Plain values.
 */
function isUniqueValues(values: unknown[]): boolean {
	const uniqueValues = [...new Set(values)];

	/** If it contains only empty values. */
	if (uniqueValues.length === 1 && values[0] === '') {
		return true;
	}

	if (uniqueValues.length < values.length) {
		return false;
	}

	return true;
}
