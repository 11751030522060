/** Gives information about amount of bytes in different data capacity units.  */
export enum DataCapacityInBytes {

	/** Bytes in byte. */
	B = 1,

	/** Bytes in bit. */
	Bit = B / 8,

	/** Bytes in kilobyte. */
	KB = B * 1024,

	/** Bytes in megabyte. */
	MB = KB * 1024,

	/** Bytes in gigabyte. */
	GB = MB * 1024,
}

export namespace DataCapacityInBytes {

	/**
	 * Transform bytes to megabytes.
	 * @param bytes Number of bytes.
	 */
	export function toMegabytes(bytes: number): number {
		return bytes / 1024 / 1024;
	}
}
